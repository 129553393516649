.header ::v-deep {
	justify-content: space-between;
	color: #FFFFFF;


	.el-input__inner {
		border: none;
		color: #ffffff;
		font-size: 15px;
		line-height: 32px;
		height: 32px;
		background: #0067a6;

		&::placeholder {
			color: #FFFFFF;
		}
	}

	.el-input__icon {
		line-height: 32px;
	}

	.el-button {
		color: #FFFFFF;
		line-height: 32px;
		height: 32px;
		border: none;
		background: #0067A6;
		border-radius: 5px;
		padding: 0 16px;
		margin-left: 10px;
	}
}


.table ::v-deep {
	//background: #FFFFFF;
	margin: 16px 0;
	text-align: center;

	.el-table, .el-table__expanded-cell {
		background: transparent;

		&:before {
			display: none;
		}
	}
	.el-tag,.el-image__error, .el-image__placeholder{
		background: transparent;
	}
	.el-table th, .el-table tr {
		background: #212844;
	}

	.el-table td, .el-table th.is-leaf {
		border-bottom: 1px solid rgba(90, 177, 239, 0.5);
	}

	.el-table__fixed-right::before, .el-table__fixed::before {
		display: none;
	}

	.el-table td {
		padding: 0;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.5);
		height: 43px;
	}

	.el-table--enable-row-hover .el-table__body tr:hover > td,
	.el-table__body tr.hover-row.current-row > td,
	.el-table__body tr.hover-row.el-table__row--striped.current-row > td,
	.el-table__body tr.hover-row.el-table__row--striped > td,
	.el-table__body tr.hover-row > td {
		background: none;
	}

	.update {
		min-width: 64px;
		height: 32px;
		background: rgba(0, 103, 166, 0.05);
		font-weight: 400;
		color: #0067A6;
		padding: 0 10px;
		border: none;
	}

	.pagination {
		padding: 10px 0;


		li {
			color: #666666;
			background: transparent;
		}

		.el-pagination__total {
			margin-left: 10px;
			color: #FFFFFF;
		}

		.btn-next, .btn-prev, li.active {
			color: #FFFFFF;
			background: transparent;
		}
	}
}

.updateMem {
	h1.tit {
		text-align: center;
		color: #373D5B;
		font-size: 1.5em;
		position: relative;
		padding-bottom: 16px;

		&:after {
			content: '';
			position: absolute;
			width: 120px;
			height: 2px;
			background: #0067A6;
			border-radius: 1px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
		}
	}
}

.rowsInfo ::v-deep {
	.el-form-item__label {
		font-weight: bold;
		color: #373D5B;
		padding: 0 10px;
	}

	.flex {
		justify-content: space-between;
	}

	.el-form-item {
		margin-bottom: 5px;
	}

	.el-input__inner, .el-textarea__inner {
		color: #373D5B;
		border: none;
		background-color: #F0F2F8;
		resize: none;
	}

	.el-select .el-input .el-select__caret, .el-input__prefix, .el-input__suffix {
		color: #373D5B;
	}

	.flex .el-input__inner, .el-date-editor.el-input, .el-date-editor.el-input__inner {
		width: 260px;
	}

	.el-input.is-active .el-input__inner, .el-input__inner:focus, .el-textarea__inner:focus {
		border-color: #373D5B;
	}
}

.addGate {
	width: 65px;
	height: 42px;
	background: rgba(0, 103, 166, 0.3);
	border-radius: 8px;
	border: none;
	font-weight: 400;
	color: #0067a6;
	font-size: 30px;
	padding: 0;
}

.gateInput {
	width: 64%;
	justify-content: space-between;

	.el-input {
		width: 260px;
	}
}

.gate {
	flex-flow: wrap;

	.gateList {
		width: 48%;
		margin-bottom: 10px;
		justify-content: end;

		.tit {
			width: 65px;
			height: 42px;
			background: #F0F2F8;
			border-radius: 8px;
			text-align: center;
			margin-right: 8px;
			color: #373D5B;
		}

		::v-deep .valueGate {
			width: calc(100% - 83px);
			background-color: #F0F2F8;
			border-radius: 4px;
			padding-right: 10px;

			.el-input {
				width: 80%;

				.el-input__inner {
					width: 100%;
				}
			}

			i {
				cursor: pointer;
			}
		}
	}
}

.checkBoxGroup ::v-deep {
	.el-checkbox {
		height: 30px;
		line-height: 30px;
		width: 150px;
		margin-right: 0;
	}

	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #D50D0E;
		border-color: #D50D0E;
	}

	.el-checkbox__inner:hover, .el-checkbox__input.is-focus {
		border-color: #D50D0E;
	}

	.el-checkbox__input.is-checked + .el-checkbox__label, .el-checkbox__label {
		color: #333333;
	}

}

.joinBlack {
	img {
		margin: auto;
		width: 83px;
		display: block;
	}

	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #373D5B;
}

.formRecord {
	align-items: normal;
	justify-content: space-between;

	.left {
		width: 47%;
		padding-right: 20px;
		border-right: 1px dashed rgba(213, 13, 14, 0.2);

		.label {
			font-weight: bold;
			color: #373D5B;
			padding: 0 10px;
		}

		::v-deep .el-input {
			width: 220px;
			display: block;
			margin: 15px 0 20px;

			.el-input__inner {
				color: #373D5B;
				border: none;
				background-color: #F0F2F8;
			}
		}

		.comInfo {
			margin-left: 10px;

		}
	}

	.rowsInfo {
		width: 47%;
		padding-left: 30px;

		.uploadImg {
			width: 138px;
			height: 42px;
			background: #F0F2F8;
			border: none;
			color: #999DAF;

			i {
				margin-right: 5px;
			}
		}
	}
}
