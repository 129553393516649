.chargeParking {
	border-radius: 8px;
	flex-wrap: wrap;
	width: 100%;

	.park {
		width: 169px;
		height: 40px;
		border: 1px solid #0067A6;
		border-radius: 10px;
		font-weight: bold;
		color: #009EFF;
		line-height: 40px;
		text-align: center;
		margin-bottom: 15px;
		cursor: pointer;
		margin-right: 2%;

		&.active {
			color: #FFFFFF;
			background-color: #0067A6;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.import {
	margin-top: 18px;
	//justify-content: space-between;

	div.model {
		width: 32%;
		height: 31vh;
		margin-right: 17px;
		flex-flow: column;
		justify-content: center;
		color: #009EFF;
		line-height: 2;
		border: 1px solid #0067A6;

		&:last-child {
			margin-right: 0;
		}

		img {
			width: 150px;
		}
	}
}

.rightNum {
	width: 100%;
	margin: 18px 0;
}


.inOut {
	//width: 700px;
}

.leftFloat {
	float: left;
}

.common {
	background: #FFFFFF;
	margin-top: 18px;
	padding: 16px;
	width: 46%;
	//margin-right: 1%;
	cursor: pointer;
	border: 1px solid #FFFFFF;
	border-radius: 8px;
	//height: 310px;
	&.maxWidth {
		width: auto;
	}

	&:hover {
		border-color: #0067A6;
		box-shadow: 0 3px 8px 0 rgba(0, 103, 166, 0.2);
	}

	&.inOver {
		width: 453px;

	}

	.tit {
		width: 46%;
		height: 40px;
		background: linear-gradient(90deg, rgba(213, 13, 14, 0.1) 0%, rgba(213, 13, 14, 0) 100%);
		line-height: 40px;
		color: #373D5B;
		padding-left: 15px;
		border-radius: 8px 0 0 0;
	}

	.leftPark {
		justify-content: space-between;
		margin-top: 20px;
	}

	.frequency {
		color: #373D5B;
		width: 50%;
	}

}

.title {
	font-size: 20px;

	span {
		font-size: 36px;
		font-weight: bold;
		color: #19D4AE;
	}

	.blue {
		color: #5AB1EF;
	}
}

.echarts {
	width: 97%;
	margin-right: 0;
	height: auto;
}

@media (min-width: 1600px) {
	.leftFloat {
		width: 46.5%;
	}
}
